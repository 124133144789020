@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  max-width: 100vw;
  box-sizing: border-box;
}

.team-profile-blob {
  width: 300px;
  height: 280px;
  background-size: cover;
  -webkit-background-position: center;
  background-position: center center;
  margin: 20px;
  animation: teamblob 3s ease-in-out infinite;
  -webkit-animation: teamblob 3s ease-in-out infinite;
  transition: all 1s ease-in-out;
  will-change: border-radius;
}

@keyframes teamblob {
  0%,
  100% {
    border-radius: 60% 40% 50% 30% / 30% 50% 50% 60%;
  }
  50% {
    border-radius: 55% 40% 30% 55% / 55% 30% 45% 45%;
  }
}
@-webkit-keyframes teamblob {
  0%,
  100% {
    border-radius: 60% 40% 50% 30% / 30% 50% 50% 60%;
  }
  50% {
    border-radius: 55% 40% 30% 55% / 55% 30% 45% 45%;
  }
}

.glass-bg {
  background: rgba(27, 27, 28, 0.557);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

@layer utilities {
  .animate-scroll-left {
    @apply animate-[scroll-left_10s_linear_infinite];
  }
  .animate-scroll-right {
    @apply animate-[scroll-right_10s_linear_infinite];
  }
}
